import {useStaticQuery, graphql} from 'gatsby';

export type ViewSortCategories = {
  productService: number[][];
  serviceComponent: number[][];
};

type UseCustomExtrasProductInclusionsSortingStatic = {
  brandContent: {
    customExtrasProductInclusionsSorting: {
      extras: ViewSortCategories;
    };
  };
};

const useCustomExtrasProductInclusionsSortingStatic = (): ViewSortCategories => {
  const {brandContent} = useStaticQuery<UseCustomExtrasProductInclusionsSortingStatic>(
    graphql`
      query CustomExtrasProductInclusionsSortingStaticQuery {
        brandContent {
          customExtrasProductInclusionsSorting {
            extras {
              productService
              serviceComponent
            }
          }
        }
      }
    `
  );

  return brandContent?.customExtrasProductInclusionsSorting?.extras || {productService: [], serviceComponent: []};
};

export default useCustomExtrasProductInclusionsSortingStatic;
