import {useMemo} from 'react';
import {useShallowEqualSelector} from '..';
import {mapSessionToPricingFactor} from '../../redux/pricing/mappers';
import {PricesByFactors} from '../../redux/pricing/pricingSlice';
import {getPricingProducts} from '../../redux/pricing/selectors';
import {PricingFactors, ProductTypes} from '../../redux/pricing/types';
import {getProductPrice} from '../../redux/pricing/utils';

export const useProductPrice = (pcatId: number, cardType: ProductTypes) => {
  const pricesForProducts = useShallowEqualSelector(getPricingProducts);
  const currentSessionFactorGroup: PricingFactors = useShallowEqualSelector(mapSessionToPricingFactor);
  const pricesByFactors: PricesByFactors = pricesForProducts[pcatId];

  if (cardType === ProductTypes.hospital && pcatId) {
    currentSessionFactorGroup.hospitalProduct = pcatId;
    currentSessionFactorGroup.extrasProduct = null;
  }

  if (cardType === ProductTypes.extras && pcatId) {
    currentSessionFactorGroup.hospitalProduct = null;
    currentSessionFactorGroup.extrasProduct = pcatId;
  }

  return useMemo(() => {
    return !pricesByFactors || !pricesByFactors.pricesByFactors ? null : getProductPrice(pricesByFactors?.pricesByFactors, currentSessionFactorGroup);
  }, [currentSessionFactorGroup, pricesByFactors]);
};
