import {useStaticQuery, graphql} from 'gatsby';

type UseSeoQuery = {
  site: {
    siteMetadata: {
      defaultTitle: string;
      defaultRobots: string;
    };
  };
  brandContent: {
    seo: {
      appleItunesApp?: string;
      appleTouchIcon?: string;
    };
  };
};

export const useSeoStatic = () => {
  const {brandContent, site} = useStaticQuery<UseSeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            defaultTitle: title
            defaultRobots: robots
          }
        }
        brandContent {
          seo {
            appleItunesApp
            appleTouchIcon
          }
        }
      }
    `
  );
  return {...site.siteMetadata, ...brandContent.seo};
};
