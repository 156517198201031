import {useStaticQuery, graphql} from 'gatsby';

type HealthInsuranceBrandNameStaticQuery = {
  brandContent: {
    coverSummaryContent: {
      healthInsuranceBrandName: string;
    };
  };
};

export const useHealthInsuranceBrandNameStatic = (): string => {
  const content = useStaticQuery<HealthInsuranceBrandNameStaticQuery>(graphql`
    query HealthInsuranceBrandName {
      brandContent {
        coverSummaryContent {
          healthInsuranceBrandName
        }
      }
    }
  `);
  return content.brandContent.coverSummaryContent.healthInsuranceBrandName;
};
