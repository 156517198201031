import {shallowEqual, useSelector} from 'react-redux';
import {ARHIJoinState} from '../../redux/rootReducer';

/* Based off node_modules\@types\react-redux\index.d.ts:572
 * [...] This interface allows you to easily create a hook that is properly typed for your store's root state.
 */

interface TypedUseSelectorHook<TState> {
  <TSelected>(selector: (state: TState) => TSelected): TSelected;
}

export const useShallowEqualSelector: TypedUseSelectorHook<ARHIJoinState> = (selector) => {
  return useSelector(selector, shallowEqual);
};
