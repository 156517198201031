import {useStaticQuery, graphql} from 'gatsby';

export type FilteredServiceComponents = {
  extras: number[];
};

type UseFilteredServiceComponentsStatic = {
  brandContent: {
    filteredServiceComponents: FilteredServiceComponents;
  };
};

const useFilteredServieComponentsStatic = (): number[] | undefined => {
  const {brandContent} = useStaticQuery<UseFilteredServiceComponentsStatic>(
    graphql`
      query FilteredServiceComponentsStaticQuery {
        brandContent {
          filteredServiceComponents {
            extras
          }
        }
      }
    `
  );

  return brandContent?.filteredServiceComponents?.extras || [];
};

export default useFilteredServieComponentsStatic;
