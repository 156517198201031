import {useStaticQuery, graphql} from 'gatsby';

type PrivacyPolicyModalStaticQuery = {
  brandContent: {
    welcomeContent: {
      privacyPolicyModal: {
        description: string;
        brokerPolicyLabel: string;
        brokerUrlType: string;
      };
    };
  };
};

export const usePrivacyPolicyModalStatic = () => {
  const {
    brandContent: {
      welcomeContent: {
        privacyPolicyModal: {brokerPolicyLabel, brokerUrlType, description}
      }
    }
  } = useStaticQuery<PrivacyPolicyModalStaticQuery>(
    graphql`
      query PrivacyPolicyModalStaticQuery {
        brandContent {
          welcomeContent {
            privacyPolicyModal {
              description
              brokerPolicyLabel
              brokerUrlType
            }
          }
        }
      }
    `
  );
  return {description, brokerPolicyLabel, brokerUrlType};
};
