import {useStaticQuery, graphql} from 'gatsby';

type PPCDateStatic = {
  ppcDate: {
    date: string;
  };
};

export const usePPCDateStatic = (): string => {
  const {ppcDate} = useStaticQuery<PPCDateStatic>(
    graphql`
      query PPCDateStaticQuery {
        ppcDate {
          date
        }
      }
    `
  );
  return ppcDate.date;
};
