export default {
  HARDCODED_VISA_STATUS: 'resident',

  VISITOR_TYPE_MAP: {
    // PHI = Private Health Insurance
    NEW_TO_PHI: 'NewToPHI',
    SWITCHER: 'Switcher'
  },

  MEMBER_STATUS_MAP: {
    POTENTIAL: 'potential',
    CURRENT: 'current'
  }
};
