import {useState} from 'react';

export const useToggle = (initialValue = false) => {
  const [state, setState] = useState<boolean>(initialValue);

  const toggleOn = () => {
    setState(true);
  };

  const toggleOff = () => {
    setState(false);
  };

  const toggle = () => {
    setState(!state);
  };

  return {state, toggleOn, toggleOff, toggle};
};

export default useToggle;
