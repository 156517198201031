import {useStaticQuery, graphql} from 'gatsby';

type SendQuoteHidePhoneStaticQuery = {
  brandContent: {
    coverSummaryContent: {
      sendQuoteHidePhone: boolean;
    };
  };
};

export const useSendQuoteHidePhoneStatic = (): boolean => {
  const content = useStaticQuery<SendQuoteHidePhoneStaticQuery>(graphql`
    query SendQuoteHidePhone {
      brandContent {
        coverSummaryContent {
          sendQuoteHidePhone
        }
      }
    }
  `);
  return content.brandContent.coverSummaryContent.sendQuoteHidePhone;
};
