import {useStaticQuery, graphql} from 'gatsby';

type ProductCardStaticQuery = {
  commonContent: {
    productCard: {
      ctaCopy: string;
      ctaFactSheet: string;
      noHospital: string;
      noExtras: string;
    };
  };
};

export const useProductCardStatic = () => {
  const {commonContent} = useStaticQuery<ProductCardStaticQuery>(
    graphql`
      query ProductCardStaticQuery {
        commonContent {
          productCard {
            ctaCopy
            ctaFactSheet
            noHospital
            noExtras
          }
        }
      }
    `
  );
  return commonContent.productCard;
};
