import React, {useState, useEffect} from 'react';
import {ChatLauncher} from '@nib/chatbot-launcher';
import {useSelector} from 'react-redux';
import {getPolicyHolderDob, getPolicyHolderFirstName, getPolicyHolderLastName} from '../../redux/session/selectors/personalDetails';
import {getPhoneNumber} from '../../redux/session/selectors/contactDetails';
import {getVisitorType, getHasCompletedAboutYou} from '../../redux/session/selectors/aboutYouDetails';

import CONFIG from './ChatLauncherProxy.config';
import Theme, {nib} from '@nib-components/theme';

/**
 * Proxy component for the `@nib/chatbot-launcher` package, which automatically
 * derives config values (such as the policy holder name, DOB, phone number etc.)
 * to pass into the chatbot launch config.
 */
const ChatLauncherProxy = () => {
  const firstName = useSelector(getPolicyHolderFirstName) ?? undefined;
  const lastName = useSelector(getPolicyHolderLastName) ?? undefined;
  const fullName = !!firstName && !!lastName ? `${firstName} ${lastName}` : undefined;

  const dateOfBirth = useSelector(getPolicyHolderDob) ?? undefined;

  // `getPhoneNumber` returns an empty string "" in the event of no value
  // We convert this to `undefined` to omit the key:value pair in the config payload
  let phoneNumber = useSelector(getPhoneNumber) ?? undefined;
  phoneNumber = phoneNumber === '' ? undefined : phoneNumber;

  // From `visitorType`, infer and map `member_status` and `interested_in_joining`
  const visitorType = useSelector(getVisitorType) ?? undefined;
  const {NEW_TO_PHI, SWITCHER} = CONFIG.VISITOR_TYPE_MAP;
  const isNotCurrentCustomer = visitorType === NEW_TO_PHI || visitorType === SWITCHER;
  const hasPotentialToJoin = isNotCurrentCustomer ? 'true' : undefined;

  const hasMadeItPastWelcomeScreen = !!useSelector(getHasCompletedAboutYou);
  const visaStatus = hasMadeItPastWelcomeScreen ? CONFIG.HARDCODED_VISA_STATUS : undefined;

  // -----------------------------------------------------
  // Determine mapped value for member status, if appropriate

  const {POTENTIAL, CURRENT} = CONFIG.MEMBER_STATUS_MAP;
  let mappedMemberStatus;

  if (hasPotentialToJoin === 'true') {
    mappedMemberStatus = POTENTIAL;
  } else if (!!visitorType && !isNotCurrentCustomer) {
    mappedMemberStatus = CURRENT;
  }

  // -----------------------------------------------------
  // Retrieve pathname from window context, when available
  // (Necessary for SSR compatibility)

  const hasWindow = typeof window !== 'undefined';
  const [pathname, setPathname] = useState<string>();

  useEffect(() => {
    if (!hasWindow) return;
    setPathname(window.location.pathname);
  }, [hasWindow]);

  // -----------------------------------------------------

  return (
    <Theme theme={nib}>
      <ChatLauncher
        config={{
          channel: 'sales',
          started_on_page: pathname,
          slots: {
            customer_name: fullName ?? firstName,
            dob: dateOfBirth,
            interested_in_joining: hasPotentialToJoin,
            member_status: mappedMemberStatus,
            phone_number: phoneNumber,
            visa_status: visaStatus
          }
        }}
      />
    </Theme>
  );
};

export default ChatLauncherProxy;
