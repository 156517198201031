import {useStaticQuery, graphql} from 'gatsby';

export type ProductRule = {
  productId: number;
  extrasRequired?: boolean;
};

type ProductRulesStatic = {
  brandContent: {
    products: {
      rules: ProductRule[];
    };
  };
};

export const useProductRulesStatic = (): ProductRule[] => {
  const content = useStaticQuery<ProductRulesStatic>(graphql`
    query ProductRules {
      brandContent {
        products {
          rules {
            productId
            extrasRequired
          }
        }
      }
    }
  `);
  return content.brandContent.products.rules || [];
};
