import {useStaticQuery, graphql} from 'gatsby';
import {RibbonOptions} from '@nib/cover-options-ribbon';

interface CustomRibbonOptions extends RibbonOptions {
  hospitalContent: {
    hospitalExcessInfoCopy: string;
  };
}

type UseRibbonOptionsStatic = {
  brandContent: CustomRibbonOptions;
};

export const useRibbonOptionsStatic = () => {
  const {brandContent} = useStaticQuery<UseRibbonOptionsStatic>(
    graphql`
      query RibbonOptionsStaticQuery {
        brandContent {
          states {
            value
            label
          }
          scales {
            value
            label
          }
          frequencies {
            value
            label
          }
          excess {
            value
            label
          }
          hospitalContent {
            hospitalExcessInfoCopy
          }
        }
      }
    `
  );
  return brandContent;
};
