import {useStaticQuery, graphql} from 'gatsby';
import {ProductTypes} from '../../redux/pricing/types';

export type SuggestedProduct = {
  productId: number;
};

export type SuggestedProducts = {
  hospital: number;
  extras: number;
};

type UseSuggestedProductsStatic = {
  brandContent: {
    suggestedProducts: {
      hospital: number;
      extras: number;
    };
  };
};

export const isSuggestedProductType = (suggestedProduct: SuggestedProduct | SuggestedProducts): suggestedProduct is SuggestedProduct => {
  return (suggestedProduct as SuggestedProduct).productId !== undefined;
};

const useSuggestedProductsStatic = (productType?: ProductTypes): SuggestedProduct | undefined => {
  const {brandContent} = useStaticQuery<UseSuggestedProductsStatic>(
    graphql`
      query SuggestedProductsStaticQuery {
        brandContent {
          suggestedProducts {
            hospital
            extras
          }
        }
      }
    `
  );

  if (productType == ProductTypes.hospital)
    return {
      productId: brandContent.suggestedProducts.hospital
    };

  if (productType == ProductTypes.extras)
    return {
      productId: brandContent.suggestedProducts.extras
    };

  return undefined;
};

export default useSuggestedProductsStatic;
