import React from 'react';
import {Helmet} from 'react-helmet';
import {useSeoStatic} from '../../hooks';
import {useSelector} from 'react-redux';
import {getFetchedSession, getJoinSource} from '../../redux/session/selectors';
import {getScale} from '../../redux/session/selectors/personalDetails';

export type SeoProps = {
  title?: string;
  description?: string;
  robots?: string;
  appleItunesApp?: string;
  appleTouchIcon?: string;
};

const SEO = ({title, description, robots}: SeoProps) => {
  const {defaultRobots, defaultTitle, appleItunesApp, appleTouchIcon} = useSeoStatic();
  const fetchedSession = useSelector(getFetchedSession);
  const scale = useSelector(getScale);
  const channel = useSelector(getJoinSource);

  const seo = {
    title: title || defaultTitle,
    description: description,
    robots: robots || defaultRobots
  };
  return (
    <Helmet>
      <title>{seo.title}</title>
      {seo.description && <meta name="description" content={seo.description} />}
      <meta name="robots" content={seo.robots} />
      {appleItunesApp && <meta name="apple-itunes-app" content={appleItunesApp} />}
      {appleTouchIcon && <link rel="apple-touch-icon" href={appleTouchIcon} />}
      {fetchedSession && <meta name="ScaleDimension" content={scale} />}
      {fetchedSession && <meta name="Channel" content={channel || process.env.GATSBY_SOURCE_CHANNEL} />}
    </Helmet>
  );
};
export default SEO;
