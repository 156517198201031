import type {Scale as TScale} from '@nib/types-session-api';
import {Scale} from '@nib/phi-constants';
import {navigate} from 'gatsby';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {ARHIPageList, ARHIPageIndex} from '../../constants';
import {getLastCompletedPage} from '../../redux/session/selectors/funnelProgress';
import {checkPageAccess, getSessionFailurePath, isCorporateBrand, isDentalBrand} from '../../services/utils';
import {ARHIPage} from '../../types/pages';
import {useShallowEqualSelector} from '../UseShallowEqualSelector';
import {getSessionError, getSessionId} from '../../redux/session/selectors';
import {getScale} from '../../redux/session/selectors/personalDetails';
import {getCookieSessionId} from '../../services/cookies';
import {getExtrasId, getHospitalId} from '../../redux/session/selectors/productSelection';

const getNextPageAvailable = (lastCompletedPage: ARHIPage, scale: TScale) => {
  if (scale === Scale.Single && lastCompletedPage.stepOrder === ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].stepOrder) {
    return ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE];
  }

  // Skip Cover Summary
  if (lastCompletedPage.stepOrder === ARHIPageList[ARHIPageIndex.EXTRAS].stepOrder) {
    return ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS];
  }

  // DentalPass entry point is Personal Details page, if there's bad state (page prior to this) we need to skip it and reset to entry.
  if (isDentalBrand() && lastCompletedPage?.stepOrder && lastCompletedPage.stepOrder <= ARHIPageList[ARHIPageIndex.EXTRAS].stepOrder) {
    return ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS];
  }

  const nextIndex = ARHIPageList.findIndex((p) => p.stepOrder === (lastCompletedPage.stepOrder || 0) + 1);
  return ARHIPageList[nextIndex];
};

/**
 * Custom hook responsible for checking client permission to visit a certain page,
 * and to take them to right page otherwise.
 *
 * The function consumes, store and return the result of 'checkPageAccess' utility function
 * and takes care of redirecting to the right page if user is not allowed to a given 'currentPage'.
 * @param currentPage
 */
export const usePageAccessControl = (currentPage: ARHIPage): boolean => {
  const cookieSessionId = getCookieSessionId();
  const fetchedSessionId = useSelector(getSessionId);
  const scale = useSelector(getScale);
  const productHospital = useSelector(getHospitalId);
  const productExtras = useSelector(getExtrasId);
  const lastCompletedPage = useShallowEqualSelector(getLastCompletedPage);
  const sessionError = useSelector(getSessionError);
  const hasSessionError = Boolean(sessionError);

  const [allowAccess, setAllowAccess] = useState(false);

  // We only want to do any form of parameter logic or session initialization / fetching logic
  useEffect(() => {
    const hasPageAccess = checkPageAccess(currentPage, lastCompletedPage, scale, {hospital: productHospital, extras: productExtras}, hasSessionError);
    // Positive cases prior to fetching session, e.g. hospital/extras pages with directQuote query param.
    if (hasPageAccess) {
      setAllowAccess(true);
      return;
    }

    if (!cookieSessionId) {
      navigate(getSessionFailurePath());
      return;
    }

    /**
     * Session dependant cases, continue with page control once session has been fetched.
     */
    if (!fetchedSessionId) {
      return;
    }

    // If no product selected navigate to Hospital
    if (!productHospital && !productExtras) {
      navigate(ARHIPageList[ARHIPageIndex.HOSPITAL].link);
      return;
    }

    // First page to change lastCompletedPage is welcome
    if (!lastCompletedPage.name) {
      if (isDentalBrand()) {
        navigate(ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].link);
      } else if (isCorporateBrand()) {
        navigate(ARHIPageList[ARHIPageIndex.HOSPITAL].link);
      } else {
        navigate(ARHIPageList[ARHIPageIndex.WELCOME].link);
      }
      return;
    }

    // Move to current available page
    if (hasPageAccess === false && lastCompletedPage) {
      const nextPage = getNextPageAvailable(lastCompletedPage, scale);
      navigate(nextPage.link);
    }
  }, [fetchedSessionId]);

  return allowAccess;
};
